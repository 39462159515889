body > canvas {
  position: fixed;
  /*z-index: -1;*/
  top: 0;
  left: 0;
  overflow: hidden;
}

/*body > canvas .demo {
  animation: fadein 2s ease-in forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}*/

.MuiPaper-root::before {
  display: none !important;
}

.MuiDrawer-paperAnchorBottom {
  max-height: 100% !important;
  /*background: none !important;*/
}

/* .MuiOutlinedInput-notchedOutline {
  border: none !important;
  outline: none !important;
} */

.carousel-scaletransform .react-swipeable-view-container [aria-hidden='true'] {
  transform: scale(0.9);
  transition: transform 0.3s;
}

.carousel-scaletransform .react-swipeable-view-container [aria-hidden='false'] {
  transform: scale(1);
  transition: transform 0.3s;
}

/* disable underlines */
:not(.keep-underline).MuiFilledInput-root:before,
:not(.keep-underline).MuiFilledInput-root:after,
:not(.keep-underline).MuiInput-root:before,
:not(.keep-underline).MuiInput-root:after {
  content: none;
}

/* Custom scrollbar - webkit only */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #004;
}
::-webkit-scrollbar-thumb {
  background: #fff4;

  background: #aeadad;
  background: #8be8ff;
  background: #9bf9d1;
  background: #fff069;
  background: #ff7d6b;
  background: #ff84c6;
  background: #14246f;
  background: #223ea4;
  background: #52c1ff;
  background: #15444d;
  background: #1f645b;
  background: #2bc0ba;
  background: #9bf9d1;
  background: #176e39;
  background: #049564;
  background: #6cffa4;
  background: #ffd912;
  background: #f7e74d;
  background: #d8e26a;
  background: #f33821;
  background: #ff653f;
  background: #fb943d;
  background: #ffa51e;
  background: #c71f33;
  background: #f34143;
  background: #b21a4b;
  background: #391c78;
  background: #660266;
  background: #c03680;
  background: #de9be2;
  background: #efefed;
  background: #1b1c1c;
}
